<template></template>

<script>
export default {
  name: "profile-edit",
  mounted() {
    this.$router.replace({
      name: "personnel-edit",
      params: { id: this.$auth.user().id },
    });
  },
};
</script>

<style>
</style>